import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { ItemGallery } from "@projects/ItemGallery"
import { ItemDetail } from "@projects/ItemDetail"
import { AccordionBox } from "@projects/AccordionBox"

import * as css from "@css/layouts/l-news-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

function NewsPage({data})
{
    console.log("=>",data)
    const { title, content, date, thumbnail } = data.post

    
    return(
        <>
            <SEO
                subtitle={title}
                description={content}
                image={thumbnail && `${thumbnail.node.localFile.publicURL}`}
            />
            <Spacer.Header/>
            <article className={css.lNewsArticle}>
                <div className={css.aInner}>
                    { thumbnail ?
                        <figure className={css.aThumbnail}>
                            <Image data={thumbnail.node.localFile.childImageSharp} />
                        </figure>
                        :null
                    }
                    <p className={css.aDate}>{date}</p>
                    <h1 className={css.aTitle}>{title}</h1>
                    <div className={css.aContent} dangerouslySetInnerHTML={{__html: content}}>
                    </div>
                </div>
            </article>
        </>
    )
}

export const pagerQuery = graphql`
    fragment News on WpNews {
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        uri
        databaseId
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 920
                            placeholder: BLURRED
                            formats: [AUTO]
                            quality: 100
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    }
    query NewsBySlug($id: String!){
        post: wpNews( id: { eq: $id }){
            ...News
        }
        dammyItem : file(relativePath: { eq: "Common__img__empty2.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 920
                    placeholder: BLURRED
                    formats: [AUTO]
                    quality: 100
                    layout: FULL_WIDTH
                )
            }
        }
    }
`
export default NewsPage